import React from "react";
import MainNavigation from "../Navigation/components/MainNavigation";
import TopBar from "../Navigation/components/TopBar/TopBar";

const BarsLoader = () => {
  return (
    <React.Fragment>
      <MainNavigation />
      <TopBar />
    </React.Fragment>
  );
};

export default BarsLoader;
