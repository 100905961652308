import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faChartPie,
  faTicketAlt,
  faCaretDown,
  faCaretUp,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

import "./Card.css";

const Card = (props) => {
  return (
    <div className="card__container" style={props.style}>
      <div className="card__left">
        {props.type === "money" && <FontAwesomeIcon icon={faDollarSign} />}
        {props.type === "chart" && <FontAwesomeIcon icon={faChartPie} />}
        {!props.type && <FontAwesomeIcon icon={faTicketAlt} />}
      </div> 
      <div className="card__center">
        <div className="card__center-number">{props.number}</div>
        <div className="card__center-title">{props.title}</div>
      </div>
      <div className="card__right">
        {props.profit === "up" && <FontAwesomeIcon icon={faCaretUp} />}
        {props.profit === "down" && <FontAwesomeIcon icon={faCaretDown} />}
        {!props.profit && <FontAwesomeIcon icon={faMinus} />}
      </div>
    </div>
  );
};

export default Card;
 