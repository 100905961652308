import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppSettings } from "../../config/AppSettings";
import { getToken } from "../../shared/authenticator/Auth/Auth";
import EditProductView from '../components/EditProductView';

const EditProduct = ()=>{

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [dataLoaded, setDataLoaded] = useState();
    const productId = useParams().productId;
  
    useEffect(() => {
      setIsLoading(true);
      const getProducts = async () => {
        try {
          const response = await fetch(`${AppSettings.EndPoint}/tours/${productId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          });
          const responseData = await response.json();
          setDataLoaded(responseData.result);
        } catch (err) {
          setError(err.message);
        }
        setIsLoading(false);
      };
      getProducts();
    }, []);

    
    return (
        <React.Fragment>
          {/* <ErrorModal error={error} onClear={errorHandler} /> */}
          {isLoading && <div className="center">spinner</div>}
          {!isLoading && dataLoaded && <EditProductView product={dataLoaded} />}
        </React.Fragment>
      );
}

export default EditProduct;


