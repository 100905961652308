import React from "react";

import './MainList.css'

const MainList = (props) => {
  return (
    <ul className={`main-list__container ${props.className}`}>
      {props.children}
    </ul>
  );
};

export default MainList;
 