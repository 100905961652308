import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import "./CloseButton.css";

const CloseButton = (props) => {
  return (
    <div className={props.className} onClick={props.onClick} onMouseOver={props.onClick}>
      <button>        
            {props.icon === 'left' && <FontAwesomeIcon icon={faChevronLeft} />}
            {props.icon === 'right' && <FontAwesomeIcon icon={faChevronRight} />}
      </button>
    </div>
  );
};

export default CloseButton;
