import React from "react";
import { faEdit, faCircle, faTrash, faList } from "@fortawesome/free-solid-svg-icons";
import Title from "../../shared/components/UIElements/Title";
import CardList from "../../shared/components/UIElements/CardList";
import CardListRow from "../../shared/components/UIElements/CardListRow";
import SearchBar from "../../shared/components/UIElements/SearchBar";
import CardListRowButton from "../../shared/components/UIElements/CardListRowButton";
import "./ListProducts.css";

const ListProducts = (props) => {

  const statusHandler = (id)=>{ 
    console.log('status pressed', id);
  }

  const editHandler = (id)=>{ 
    console.log('edit pressed', id);
  }

  const deleteHandler = (id)=>{ 
    console.log('delete pressed', id);
  }

  const productSelectedHandler = (event)=>{ 
    event.preventDefault();
    console.log('product selected pressed', event.target.id);
  }

  return (
    
    <div className="list-products__container">

      <Title title="LIST PRODUCTS" icon={faList} />

      <SearchBar classNameTarget="item-filter" placeholder="Search" className="text-center" /> 
      {console.log(props.products)}
      {props.products.map((product) => {
        return (
          <CardList key={product.id} id={product.id} dataSet={product.name} className="list-products__list-container item-filter">
            <CardListRow className="list-products__detail-a" value={product.id} id={product.id} onClick={productSelectedHandler}/>
            <CardListRow className="list-products__detail-b" value={product.name} id={product.id} onClick={productSelectedHandler}/>
            {/* <CardListRow className="list-products__detail-c" value={product.price} id={product.id} onClick={productSelectedHandler}/> */}
            <CardListRow className="list-products__detail-d" value={product.category.category} id={product.id} onClick={productSelectedHandler}/>
            <CardListRow className="list-products__detail-button"> 
              <CardListRowButton className={`text-${product.status ? 'success' : 'gray'}`} icon={faCircle} onClick={statusHandler} id={product.id}/>
              <CardListRowButton className="text-warning" icon={faEdit} onClick={editHandler} id={product.id}/>
              <CardListRowButton className="text-danger" icon={faTrash} onClick={deleteHandler} id={product.id}/>
            </CardListRow>

          </CardList>
        );
      })}
      
    </div>
  );

};

export default ListProducts;
 