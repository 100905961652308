import React, { useState } from "react";
import ReactDOM from "react-dom";

import NavList from "./NavList";
import SideDrawer from "./SideDrawer";
import "./MainNavigation.css";
import CloseButton from "./CloseButton";

const MainNavigation = (props) => {

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [iconSide, setIconSide] = useState({side:'right', open:false, close:true}); 

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
    setTimeout(()=>{
      setIconSide({side:'left', open:true, close:false});
    }, 500);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);    
    setTimeout(()=>{
      setIconSide({side:'right', open:false, close:true}) ;
    }, 500);   
  };
  
  const mainNav = (
    <React.Fragment>
      <SideDrawer show={drawerIsOpen} >
          <nav className="main-navigation__drawer-nav">
            <NavList onClick={closeDrawerHandler} />
          </nav>  
          {iconSide.open && <CloseButton className="close-button" icon={iconSide.side} onClick={closeDrawerHandler}/>}
      </SideDrawer>
          {iconSide.close && <CloseButton className="close-button-closed" icon={iconSide.side} onClick={openDrawerHandler}/>}
    </React.Fragment>
  );

  return ReactDOM.createPortal(mainNav, document.getElementById("lateralBar-hook"));

};

export default MainNavigation;
