import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Title.css";

const Title = (props) => {
  return (
    <div className="title__container">
      {" "}
      <div className="title__container-items">
        <span>{props.icon && <FontAwesomeIcon icon={props.icon} />}</span>
        <span>{props.title}</span>
      </div>{" "}
    </div>
  );
};

export default Title;
