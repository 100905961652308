import React, { useState, useEffect } from "react";
import { getToken } from "../../shared/authenticator/Auth/Auth";
import { AppSettings } from "../../config/AppSettings";
import ListProducts from "../components/ListProducts";
import axios from "axios";
const Products = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [dataLoaded, setDataLoaded] = useState();

  useEffect(() => {
    setIsLoading(true);
    const getProducts = async () => {


      axios({
              method:'GET',
              url:`${AppSettings.EndPoint}/tours/`,
              headers: {'Authorization': `Bearer 2487|tBM7EHi0mI55OWNCBUbF9HoG45ng8JZrLfvZwVyo`, 'Accept':'application/json', 'Access-Control-Allow-Origin':'*', 'Vary': 'Authorization'},
              // headers: {'Authorization': `Bearer ${getToken()}`, 'Accept':'application/json', 'Access-Control-Allow-Origin':'*', 'Vary': 'Authorization'},
              responseEncoding: 'application/json',
              responseType: 'application/json',
      })
      .then(response => console.log(response))
      .catch(error => console.log(error));

        // const response = await fetch(`${AppSettings.EndPoint}/tours/`, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Accept: "application/json",
        //     Authorization: `Bearer ${getToken()}`,
        //   },
        // });
        // const responseData = await response.json();
        // setDataLoaded(responseData[0].result);




      setIsLoading(false);
    };
    getProducts();
  }, []);

//   const errorHandler = () => {
//     setError(null);
//   };

  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={errorHandler} /> */}
      {isLoading && <div className="center">spinner</div>}
      {!isLoading && dataLoaded && <ListProducts products={dataLoaded} />}
    </React.Fragment>
  );
};

export default Products;
