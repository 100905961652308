import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Input from "../../shared/components/FormElements/Input";
import { CATEGORY, DEFAULT_CATEGORY } from "../../shared/util/products/categoryProducts";
import { VALIDATOR_REQUIRE, VALIDATOR_MIN } from "../../shared/util/validators";
import Title from "../../shared/components/UIElements/Title";

import "./EditProductView.css";

const EditProductView = (props) => {
  return (
    <div className="main-new-product__input-content">
        <Title title="NEW PRODUCT" icon={faPlus} />
      <div className="main-new-product__input-container">
        <div className="main-new-product__input-elements">
          <Input
            element="input"
            id="name-product"
            name="name-product"
            label="Product name"
            placeholder="Name of the product"
            className="main-new-product__input-items"
            type="text"
            value={props.product.name}
            validators={[VALIDATOR_REQUIRE()]}
          />
        </div>
        <div className="main-new-product__input-elements">
          <Input
            id="categoryid"
            name="category"
            label="Category"
            element="select"
            defaultValue={DEFAULT_CATEGORY}
            placeholder="Type product category"
            className="main-new-product__input-items"
            type="text"
          >
            {CATEGORY.map((categories) => {
              return (
                <option
                  key={categories.value}
                  className="main-new-product__input-items-option"
                  value={categories.value}
                >
                  {categories.title}
                </option>
              );
            })}
          </Input>
        </div>

        <div className="main-new-product__input-elements">
          <Input
            className="main-new-product__input-items"
            id="quanty-product"
            name="quanty-product"
            label="Quantity"
            element="input"
            placeholder="0"
            type="number"
            validators={[VALIDATOR_MIN(1)]}
          />
        </div>
        <div className="main-new-product__input-elements">
          <Input
            className="main-new-product__input-items"
            id="unit-product"
            name="unit-product"
            label="Unit type"
            element="input"
            placeholder="[ kg, lbs, lits, int ]"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
          />
        </div>
        <div className="main-new-product__input-elements">
          <Input
            element="input"
            id="cost-product"
            name="cost-product"
            label="Cost price"
            placeholder="0"
            className="main-new-product__input-items"
            type="number"
            validators={[VALIDATOR_MIN(1)]}
          />
        </div>
        <div className="main-new-product__input-elements">
          <Input
            element="input"
            id="price-product"
            name="price-product"
            label="Sale price"
            placeholder="0"
            className="main-new-product__input-items"
            type="number"
            validators={[VALIDATOR_MIN(1)]}
          />
        </div>

        <div className="main-new-product__input-elements">
          <Input
            id="descriptionID"
            name="Description"
            label="Description"
            placeholder="Type product category"
            className="main-new-product__input-items"
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export default EditProductView;

