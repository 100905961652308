import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { logOut } from "../../../../authenticator/Auth/Auth";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "./TopBar.css";

const Topbar = () => {
    let history = useHistory();

    const logoutHandler = () => {
        logOut();
        history.replace("/login/");
    }

    const topBarElement = (
    <div className="top-bar__container">
        <span className="top-bar__icon_span" onClick={logoutHandler}><FontAwesomeIcon icon={faSignOutAlt} /></span>
    </div>
    );

    return ReactDOM.createPortal(
        topBarElement,
        document.getElementById("topBar-hook")
    );
};

export default Topbar;
