import React from "react";
import Title from "../../shared/components/UIElements/Title";
import ChartSection from "../components/ChartSection";
import TopSection from "../components/TopSection";
import { faGripHorizontal } from "@fortawesome/free-solid-svg-icons";

const Home = (props) => {
  return (
    <React.Fragment>
      <div className="home__container">
        <Title title="DASHBOARD" icon={faGripHorizontal} />
        <TopSection />
        <ChartSection />
      </div>
    </React.Fragment>
  );
};

export default Home; 
