import React from "react";

import "./Button.css";

const Button = (props) => {
  return (
    <div className={`${props.className} button-component__container`}>
      <button onClick={props.onClick} value={props.value}>{props.title}</button>
    </div>
  );
};

export default Button;
