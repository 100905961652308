import React from "react";
import { faHome, faBoxOpen, faUsers, faChartPie, faPlus, faChartLine, faUserCheck, faList } from "@fortawesome/free-solid-svg-icons";

import { AppSettings } from "../../../../config/AppSettings";
import Divisor from "./Divisor";
import MainList from "./MainList";
import NavItem from "./NavItem";

import "./NavList.css";

const NavList = props => {

  return (
    <header>

      <span className="center mt-4 text-uppercase fw-bold">{AppSettings.AppName}</span>

      <Divisor className="text-align-center" />

        <MainList>

          <NavItem icon={faHome} name="Dashboard" to="/" badgeBg="primary" badge={1} onClick={props.onClick}/>

          <NavItem className="dropdown__container" icon={faBoxOpen} name="Products" badge={1}>
            <MainList className="dropdown__parent">
              <NavItem className="dropdown__son" icon={faList} name="Products list" to="/products/" onClick={props.onClick} isSub={true}/>
              <NavItem className="dropdown__son" icon={faPlus} name="Add products" to="/products/new" onClick={props.onClick} isSub={true}/>
              <NavItem className="dropdown__son" icon={faChartLine} name="Reports" to="/products/reports" onClick={props.onClick} isSub={true}/>
            </MainList>                
          </NavItem>

          <NavItem className="dropdown__container" icon={faUsers} name="Users" badge={1}>
            <MainList className="dropdown__parent">
              <NavItem className="dropdown__son" icon={faUserCheck} name="User list" to="/user/" onClick={props.onClick} isSub={true}/>
              <NavItem className="dropdown__son" icon={faPlus} name="Add user" to="/user/new" onClick={props.onClick} isSub={true}/>
              <NavItem className="dropdown__son" icon={faChartLine} name="Reports" to="/user/reports" onClick={props.onClick} isSub={true}/>
            </MainList>                
          </NavItem>

          <NavItem icon={faChartPie} name="Analytics" to="/analytics" badgeBg="primary" badge={1} onClick={props.onClick} />

        </MainList>

      <Divisor />

    </header>
  );
};
export default NavList;

 
