import React from "react";
import Input from "../FormElements/Input";

import "./SearchBar.css";

const SearchBar = (props) => {

  const searchBarHandler = (e) => {
    e.preventDefault();

    const inputValue = e.target.value;
    const container = document.querySelectorAll(`.${props.classNameTarget}`);
    const regularExpressionInput = new RegExp("\\b\\w*" + inputValue.toLowerCase() + "\\w*\\b");

    for (let n = 0; n < container.length; n++) {
      if (container[n].dataset.name === e.target.value ||e.target.value.length === 0) {
        container[n].style.display = "flex";
      } else {
        if (container[n].dataset.name.toLowerCase().match(regularExpressionInput)) {
          container[n].style.display = "flex";
        } else {
          container[n].style.display = "none";
        }
      }
    };

  };

  return (
    <div className={`search-bar__container ${props.className}`}>
      <Input element="input" className={`search-bar__container-input ${props.className}`}  type="text" placeholder={props.placeholder} onChange={searchBarHandler}/>
    </div>
  );
};

export default SearchBar;
