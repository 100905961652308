import React from "react";
import { Row, Col } from "react-bootstrap";
import Chart from "../../shared/components/UIElements/Chart";

import "./ChartSection.css";

const ChartSection = (props) => {
  
  const chartData = [
                      { id: 1, name: "Group A", value: 1000 },
                      { id: 2, name: "Group B", value: 300 },
                      { id: 3, name: "Group C", value: 300 },
                      { id: 4, name: "Group D", value: 200 },
                    ];
  const chartColor = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <React.Fragment>
      <div className="chart-section__container">
        <Row>
          <Col>
            <div className="chart-section__chart-container">
              <Chart title="Weekly Sales" data={chartData} colors={chartColor} />
            </div>
          </Col>
          <Col>
            <div className="chart-section__chart-container">
            <Chart title="Tikets Processed" data={chartData} colors={chartColor} />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );


};

export default ChartSection;
