// Getting react library
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { Container } from "react-bootstrap";

import BarsLoader from "./shared/components/BarsLoader/BarsLoader";
import Home from "./home/pages/Home";
import Products from "./products/pages/Products";
import NewProduct from "./products/pages/NewProduct";
import ReportsProduct from "./products/pages/ReportsProduct";
import Footer from "./shared/components/Footer/Footer";
import Login from "./auth/pages/login";
import ProtectedRoutes from "./shared/authenticator/Auth/ProtectedRoutes";
import PublicRoutes from "./shared/authenticator/Auth/PublicRoutes";
import EditProduct from "./products/pages/EditProduct";

const App = () => {
  return (
    <Router>   

      <PublicRoutes>
        <Container>
          <Switch>
            <Route path="/login/" exact>
              <Login />
            </Route>
          </Switch>
        </Container>
      </PublicRoutes>

      <ProtectedRoutes>
      
        <Container>
          <Switch>
            <Route path="/" exact>
              <BarsLoader />
              <Home />
            </Route>
            <Route path="/products/" exact>
              <BarsLoader />
              <Products />
            </Route>
            <Route path="/:productId/products/" exact>
              <BarsLoader />
              <EditProduct />
            </Route>
            <Route path="/products/new" exact>
              <BarsLoader />
              <NewProduct />
            </Route>
            <Route path="/products/reports" exact>
              <BarsLoader />
              <ReportsProduct />
            </Route>
          </Switch>
        </Container>
      </ProtectedRoutes>

      <Footer />

    </Router>
  );
};
// Exporting app constant as a component
export default App;
