// Getting react library
import React from 'react';
import ReactDOM from 'react-dom';
import { AppSettings } from './config/AppSettings';
import 'bootstrap/dist/css/bootstrap.min.css';

// importing CSS
import './index.css';
// Importing App.js file
import App from './App';

document.querySelector('#app__title').innerHTML = AppSettings.AppName;
// Sending app to html DOM
ReactDOM.render(<App />, document.getElementById('root')); 