import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../../shared/components/UIElements/Card";

import "./TopSection.css";

const TopSection = (props) => {

  return (
    <React.Fragment> 
      <div className={`top-section__container`} id="top-section__container">
        <Row>
          <Col>
            <Card
              number={"1,700,258.00"}
              title={"Weak Sales"}
              style={{ backgroundColor: "#9e19ae" }}
            >
            </Card>
          </Col>
          <Col>
            <Card
              type={"money"}
              number={"1,700,258.00"}
              title={"Sales Earned"}
              profit={"down"}
              style={{ backgroundColor: "#c2562d" }}
            >
            </Card>
          </Col>
          <Col>
            <Card
              type={"chart"}
              number={"1,700,258.00"}
              title={"General Balance"}
              profit={"up"}
              style={{ backgroundColor: "#ce0505" }}
            >
            </Card>
          </Col>
          
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TopSection;
