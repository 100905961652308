import React, { useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import "./Chart.css";

const Chart = (props) => {
  const [percenting, setPercenting] = useState(0);
  const [backgroundColorPercent, setBackgroundColorPercent] = useState('');

  const data = props.data || [];
  const COLORS = props.colors || [];
  // const RADIAN = Math.PI / 180;

  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   // setPercenting([percent])
  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="white"
  //       textAnchor={x > cx ? "start" : "end"}

  //     >
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };
  const getPercentHandler = (data) => {
    setPercenting(data.percent);
    setBackgroundColorPercent(data.fill);
  };
  const getPercentLegendHandler = (e)=>{
    e.preventDefault();
    
  }

  return (
    <div className={`chart__container ${props.className}`}>
      <div className="chart__title">{props.title}</div>

      <div className="chart__pie">
        <div className="chart__pie-chart">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              {/* <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} /> */}
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                // label={renderCustomizedLabel}
                startAngle={180}
                endAngle={-180}
                innerRadius={45}
                outerRadius={65}
                paddingAngle={6}
                dataKey="value"
                onMouseOver={getPercentHandler}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        
        <div className="chart__pie-legends">
          <div className="chart__pie_percent" style={{color: backgroundColorPercent}}>{`${(percenting * 100).toFixed(0)}%`}</div>
          <ul>
            {data.map((entry, index) => {
              return (
                <li
                  key={entry.id}
                  id={entry.id}
                  style={{ color: `${COLORS[index % COLORS.length]}` }}
                  onClick={getPercentLegendHandler}
                >
                  <FontAwesomeIcon icon={faSquare} /> {entry.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Chart;
