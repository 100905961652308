import React from "react";
import ReactDOM from "react-dom";

import "./Footer.css";

const Footer = (props) => {

  const footerElement = (
    <div className="footer__container">
      <span>MARKETSBYAPP © {new Date().getFullYear()}</span>
    </div>
  );

  return ReactDOM.createPortal(footerElement, document.getElementById("footer"));
};

export default Footer;
