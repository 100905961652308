import React from "react";
import { useHistory } from "react-router-dom";
import { getToken } from "./Auth";
const PublicRoutes = (props) => {
    
    let history = useHistory();

    if (!getToken()) {
        if(history.location.pathname !== "/login/"){history.replace("/login/")}
    }
    return <React.Fragment>{props.children}</React.Fragment>;

  
};

export default PublicRoutes;