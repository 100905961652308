import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CardListRowButton.css";

const CardListRowButton = (props) => {
  return (
    <span
      className={`card-list-row-button  ${props.className}`}
      id={props.id}
      onClick={props.onClick.bind('' , props.id)}
    >
      <FontAwesomeIcon icon={props.icon} />
    </span>
  );
};

export default CardListRowButton;
