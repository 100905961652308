import React from "react";

import './Divisor.css';

const Divisor = (props) => {
  return (
    <hr className={`divisor ${props.className}`}/>
  );
};

export default Divisor;
