import React, { useState } from "react";


import InputMask from "react-input-mask";
import { validate } from "../../util/validators";


import "./Input.css";



const Input = (props) => {

  const [inputValidation, setInputValidation] = useState({value:'', isValid:true, validators: props.validators, validated:false});

  const inputValidationHandler = (e) => {
    e.preventDefault();
    setInputValidation({value: e.target.value, isValid: validate(e.target.value, inputValidation.validators), validators: inputValidation.validators, validated:true});
  }

  const element =
    props.element === "input" ? (
      props.mask ? (
        <InputMask mask={props.mask} value={props.value} alwaysShowMask={props.alwaysShowMask}>
          {(inputProps) => (
            <input
              {...inputProps}
              className='input-component__son-element-input'
              type={props.type}
              id={props.id}
              placeholder={props.maskPlaceholder}
              onChange={props.onChange}
              autoComplete={props.autoComplete}
              name={props.name}
              value={props.value}
            />
          )}
        </InputMask>
      ) : (
        <input
          type={props.type}
          id={props.id}
          className='input-component__son-element-input'
          placeholder={props.placeholder}
          onChange={props.onChange}
          onChangeCapture={props.validators && inputValidationHandler}  
          onBlur={props.onBlur ? props.onBlur : (props.validators && inputValidationHandler) }    
          autoComplete={props.autoComplete}
          name={props.name}
          defaultValue={props.value}
        />
      )
    ) : (props.element === "select" ? <select className='input-component__son-element-input' id={props.id} defaultValue={props.defaultValue}>{props.children}</select>  : <textarea className='input-component__son-element-input' id={props.id} rows={props.rows || 3}></textarea>);

  return (
    <div className={`${props.className} input-component__container ${!inputValidation.isValid ? 'error-no-valid' : ( inputValidation.validated ? 'success-no-valid' : '' )}`}>
      <label className='input-component__son-element-label' htmlFor={props.id}>{props.label}</label>
      {element}
    </div>
  );
};

export default Input;