import React, { useState, useEffect } from "react";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Title from "../../shared/components/UIElements/Title";
import "./LoginForm.css";
import { login, getToken } from "../../shared/authenticator/Auth/Auth";

import { useHistory } from "react-router-dom";

const LoginForm = (props) => {


  let history = useHistory();


  if (getToken()) {
    if(history.location.pathname === "/login/"){history.replace("/")} 
  }

  const [formdata, setFormData] = useState({
    email: "email",
    password: "password",
  });

  const [doneForLog, setDoneForLog] = useState(false);

  const saveFormDataHandler = (event) => {
    event.preventDefault();
    event.target.id === "password" &&
      setFormData({ email: formdata.email, password: event.target.value });
    event.target.id === "email" &&
      setFormData({ email: event.target.value, password: formdata.password });
  };

  const setDoneForLogHandler = () => {
    setDoneForLog(true);
  };

  const sendToHomehandler =()=>{
    if(getToken()){
      history.replace("/");
    }
  }

  useEffect(() => {
    const loginActionHandler = async () => {
      await login(formdata);
      if(doneForLog){
        setTimeout(sendToHomehandler, 2000)
      }
    };
    loginActionHandler();
  }, [doneForLog]);

  return (
    <React.Fragment>
      <div className="login-form__container">
        <Title title="SIGN IN" icon={faSignInAlt} />
        <div className="login-form__container_inputs">
          <Input
            onBlur={saveFormDataHandler}
            onChange={saveFormDataHandler}
            id="email"
            type="text"
            element="input"
            placeholder="email"
            autoComplete="true"
          />
        </div>
        <div className="login-form__container_inputs">
          <Input
            onBlur={saveFormDataHandler}
            onChange={saveFormDataHandler}
            id="password"
            type="password"
            element="input"
            placeholder="password"
            autoComplete="true"
          />
        </div>
        <div className="login-form__container_inputs">
          <Button onClick={setDoneForLogHandler} title="Sign In" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
