import React from "react";
import "./CardList.css";
const CardList = (props) => {
  return (
    <div data-name={props.dataSet} className={`card-list__container ${props.className}`}>
      {props.children}
    </div>
  );
};
export default CardList;
