import { AppSettings } from "../../../config/AppSettings";
import axios from "axios";

export const setToken = (token) => {
        localStorage.setItem(AppSettings.TokenKey, token);
}

export const removeToken = () => {
        localStorage.removeItem(AppSettings.TokenKey);
}

export const getToken = () => {
        return localStorage.getItem(AppSettings.TokenKey);
}

export const login = async (userData) => {
    if(!getToken()){
          axios({
            method:"POST",
            url: `${AppSettings.EndPoint}/auth/login`,
            responseEncoding: "application/json",
            responseType: "application/json",
            data:{
              email: userData.email,
              password: userData.password,
            }
          }).then(response => {
              return(response.data);

          }).then(data => {
            if(!data.message){
              setToken(data.token); 
              return true;
            };

          }).catch(error => removeToken());


    }else{
      return false
    }

}

export const logOut = () => {

    fetch(`${AppSettings.EndPoint}/auth/logout`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            }
        }).catch(error => console.log(error));

        removeToken()
        

}