import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";
import "./NavItem.css";

const NavItem = (props) => {
  return (
    <li className={`nav-item__container ${props.className}`} onClick={props.onClick}>

      {props.to ? (

        <NavLink to={props.to} exact>

          {props.icon ? (<span className="nav-item__firstChild"><FontAwesomeIcon icon={props.icon} /></span>) : 
            <span className="nav-item__firstChild"></span> }
          

          {props.name ? (<span className="nav-item__secondChild">{props.name}</span>) : (
            <span className="nav-item__secondChild">{props.children}</span>)}

          {!props.isSub && props.badge ? (
            <span className="nav-item__thirdChild"><Badge bg={props.badgeBg}>{props.badge}</Badge></span>): 
              <span className="nav-item__thirdChild"></span>}
              
        </NavLink>

      ) : (
        <React.Fragment>
          <div>
            <span className="nav-item__firstChild"> <FontAwesomeIcon icon={props.icon} /> </span>
            <span className={`nav-item__secondChild ${props.clasNameSecond}`} onClick={props.onClick}>{props.name}</span>
            <span className="nav-item__thirdChild"><Badge bg={props.badgeBg}>{props.badge}</Badge></span>
          </div>
          {props.children}
        </React.Fragment>
      )}


      
    </li>
  );
};

export default NavItem;
 